import React from 'react';
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar/Navbar'
import image1 from '../images/galleryImage1.jpg'
import image2 from '../images/galleryImage2.jpg'
import image3 from '../images/galleryImage3.jpg'
import image4 from '../images/galleryImage4.jpg'
import image5 from '../images/galleryImage5.jpg'
import image6 from '../images/galleryImage6.jpg'
import image7 from '../images/galleryImage7.jpg'
import image8 from '../images/galleryImage8.jpg'
import image9 from '../images/galleryImage9.jpg'
import image10 from '../images/galleryImage10.jpg'
import image11 from '../images/galleryImage11.jpg'
import image12 from '../images/galleryImage12.jpg'
import image13 from '../images/galleryImage13.jpg'
import image14 from '../images/galleryImage14.jpg'
import image15 from '../images/galleryImage15.jpg'
import image16 from '../images/galleryImage16.jpg'
import image17 from '../images/galleryImage17.jpg'
import image18 from '../images/galleryImage18.jpg'
import image19 from '../images/galleryImage19.jpg'
import image20 from '../images/galleryImage20.jpg'
import image21 from '../images/galleryImage21.jpeg'
import image22 from '../images/galleryImage22.jpeg'
import image23 from '../images/galleryImage23.jpeg'
import image24 from '../images/galleryImage24.jpeg'
import image25 from '../images/galleryImage25.jpeg'
import image26 from '../images/galleryImage26.jpeg'
import image27 from '../images/galleryImage27.jpeg'
import image28 from '../images/galleryImage28.jpeg'
import image29 from '../images/galleryImage29.jpeg'
import image30 from '../images/galleryImage30.jpeg'
import image31 from '../images/galleryImage31.jpeg'
import image32 from '../images/galleryImage32.jpeg'
import image33 from '../images/galleryImage33.jpeg'
import image34 from '../images/galleryImage34.jpeg'
import image35 from '../images/galleryImage35.jpeg'
import image36 from '../images/galleryImage36.jpeg'
import image37 from '../images/galleryImage37.jpeg'
import image38 from '../images/galleryImage38.jpeg'
import image39 from '../images/galleryImage39.jpeg'
import image40 from '../images/galleryImage40.jpeg'
import image41 from '../images/galleryImage41.jpeg'
import image42 from '../images/galleryImage42.jpeg'
import image43 from '../images/galleryImage43.jpeg'
import image44 from '../images/galleryImage44.jpeg'
import image45 from '../images/galleryImage45.jpeg'
import image46 from '../images/galleryImage46.jpeg'
import image47 from '../images/galleryImage47.jpeg'
import image48 from '../images/galleryImage48.jpeg'
import image49 from '../images/galleryImage49.jpeg'
import image50 from '../images/galleryImage50.jpeg'
import image51 from '../images/galleryImage51.jpeg'
import image52 from '../images/galleryImage52.jpeg'
import image53 from '../images/galleryImage53.jpeg'
import image54 from '../images/galleryImage54.jpeg'
import image55 from '../images/galleryImage55.jpeg'
import image56 from '../images/galleryImage56.jpeg'
import image57 from '../images/galleryImage57.jpeg'
import image58 from '../images/galleryImage58.jpeg'
import image59 from '../images/galleryImage59.jpeg'
import image60 from '../images/galleryImage60.jpeg'
import image61 from '../images/galleryImage61.jpeg'
import image62 from '../images/galleryImage62.jpeg'
import image63 from '../images/galleryImage63.jpeg'
import image64 from '../images/galleryImage64.jpeg'

const photos = [
  { src: image1, alt: 'Memory 1' },
  { src: image2, alt: 'Memory 1' },
  { src: image3, alt: 'Memory 1' },
  { src: image4, alt: 'Memory 1' },
  { src: image5, alt: 'Memory 1' },
  { src: image6, alt: 'Memory 1' },
  { src: image7, alt: 'Memory 1' },
  { src: image8, alt: 'Memory 1' },
  { src: image9, alt: 'Memory 1' },
  { src: image10, alt: 'Memory 1' },
  { src: image11, alt: 'Memory 1' },
  { src: image12, alt: 'Memory 1' },
  { src: image13, alt: 'Memory 1' },
  { src: image14, alt: 'Memory 1' },
  { src: image15, alt: 'Memory 1' },
  { src: image16, alt: 'Memory 1' },
  { src: image17, alt: 'Memory 1' },
  { src: image18, alt: 'Memory 1' },
  { src: image19, alt: 'Memory 1' },
  { src: image20, alt: 'Memory 1' },

  { src: image21, alt: 'Memory 1' },

{ src: image22, alt: 'Memory 1' },

{ src: image23, alt: 'Memory 1' },

{ src: image24, alt: 'Memory 1' },

{ src: image25, alt: 'Memory 1' },

{ src: image26, alt: 'Memory 1' },

{ src: image27, alt: 'Memory 1' },

{ src: image28, alt: 'Memory 1' },

{ src: image29, alt: 'Memory 1' },

{ src: image30, alt: 'Memory 1' },

{ src: image31, alt: 'Memory 1' },

{ src: image32, alt: 'Memory 1' },

{ src: image33, alt: 'Memory 1' },

{ src: image34, alt: 'Memory 1' },

{ src: image35, alt: 'Memory 1' },

{ src: image36, alt: 'Memory 1' },

{ src: image37, alt: 'Memory 1' },

{ src: image38, alt: 'Memory 1' },

{ src: image39, alt: 'Memory 1' },

{ src: image40, alt: 'Memory 1' },

{ src: image41, alt: 'Memory 1' },

{ src: image42, alt: 'Memory 1' },

{ src: image43, alt: 'Memory 1' },

{ src: image44, alt: 'Memory 1' },

{ src: image45, alt: 'Memory 1' },

{ src: image46, alt: 'Memory 1' },

{ src: image47, alt: 'Memory 1' },

{ src: image48, alt: 'Memory 1' },

{ src: image49, alt: 'Memory 1' },

{ src: image50, alt: 'Memory 1' },

{ src: image51, alt: 'Memory 1' },

{ src: image52, alt: 'Memory 1' },

{ src: image53, alt: 'Memory 1' },

{ src: image54, alt: 'Memory 1' },

{ src: image55, alt: 'Memory 1' },

{ src: image56, alt: 'Memory 1' },

{ src: image57, alt: 'Memory 1' },

{ src: image58, alt: 'Memory 1' },

{ src: image59, alt: 'Memory 1' },

{ src: image60, alt: 'Memory 1' },

{ src: image61, alt: 'Memory 1' },

{ src: image62, alt: 'Memory 1' },

{ src: image63, alt: 'Memory 1' },

{ src: image64, alt: 'Memory 1' },
  
];

const PhotosCard = () => (
  <div>
  <Navbar />
  <div className="min-h-screen bg-gray-100 py-8 px-4">
    <h1 className="text-3xl md:text-4xl font-bold text-center mb-8">Our Memories</h1>
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {photos.map((photo, index) => (
          <div key={index} className="relative overflow-hidden rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105">
            <img src={photo.src} alt={photo.alt} className="w-full h-64 sm:h-80 lg:h-full object-cover" />
          </div>
        ))}
      </div>
    </div>
  </div>
  <Footer/>
    </div>
);

export default PhotosCard;
